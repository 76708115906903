.bg-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../../imgs/empcare_5.webp");
  height: 60vh;
  width: 100vw;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 16px 16px;
}

.fa-chevron-right::before {
  color: white !important;
  font-size: 1.5rem;
}

.link-container:hover {
  cursor: pointer;
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.quick-links {
  font-size: 2.25rem;
  margin-bottom: 0;
  text-decoration: none;
  color: white;
}

.quick-links:hover {
  color: white;
}

.link-list-group {
  columns: 2;
}

@media (max-width: 992px) {
  .bg-image {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("../../../imgs/empcare_5.webp");
    height: 60vh;
    left: 0;
    right: 0;
    background-attachment: scroll;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    padding: 16px 16px;
  }

  .link-container:hover {
    cursor: pointer;
    transform: scale(1.1);
    transition: all 0.3s ease-in-out;
  }

  .fa-chevron-right::before {
    font-size: 1rem;
  }

  .quick-links {
    font-size: 1.23rem;
    margin-bottom: 0;
    text-align: left !important;
    align-content: center;
    display: flex;
    align-items: center;
    padding-bottom: 0px !important;
  }

  .link-list-group {
    columns: 1;
  }
}