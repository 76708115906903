.banner-div {
  background-color: var(--themeColor);
  box-shadow: 5px 10px 15px var(--shadowColor);
}

.banner-text {
  color: white;
  /* margin-bottom: 0; */
}

.banner-btn {
  background-color: white;
  color: black;
  border-radius: 10px;
  border: 1px solid white;
  text-decoration: none;
}

.banner-btn:hover {
  background-color: transparent;
  color: white;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 992px) {
  .banner-text {
    margin-bottom: 0.5rem !important;
    font-size: 1.4rem;
  }
}