@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=Montserrat&display=swap');

* {
  scroll-behavior: smooth;
  font-family: 'Montserrat';
  z-index: 0;
}

:root {
  --shadowColor: #2222222a;
  --themeColor: #00df7f;
  --admissionFormColor: rgb(145,85,167);
  /* #6DCCA3 */
}

#root {
  min-height: 100vh  
}

body {
  overflow-x: hidden;
  position: relative;
}

.max-100 {
  max-width: 100% !important;
}

.link-line::after{
  content: "";
  display: block;
  width: 0px;
  height: 2px;
  background-color: var(--themeColor);
  transition: width 0.5s;
  
}

.link-line:hover::after{
  width: 100%;
  transition: width 0.5s;
}

.small-caps {
  font-variant: small-caps;
}

@keyframes drop {
  from {
      opacity: 0;
      transform: translateY(-80px);
  }
  to {
      opacity: 1;
      transform: translateY(0px);
  }
}

@keyframes rise {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to  {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes zoom-out {
  from {
      opacity: 0.25;
      transform: scale(1.25)
  }
  /* to {
      opacity: 1;
      transform: scale(1);
  } */
}