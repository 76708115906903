.logo {
  max-height: 100px;
}

.navbar {
  box-shadow: 0px 10px 10px var(--shadowColor);
  z-index: 1 !important;
}

.nav-link {
  font-size: 1.25rem !important;
}

@media (max-width: 500px) {
  .logo {
    max-height: 80px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .nav-link {
    font-size: 1.1rem !important;
    margin-left: 0.25rem;
  }
}
