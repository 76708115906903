.res-img {
  max-width: 100%;
}

.img-link {
  max-width: 250px;
}

.resources-container {
  min-height: 80vh;
  margin-bottom: 2rem;
}