.contact-page-container {
  min-height: 100vh;
}

.contact-info {
  text-align: center;
  
}

@media (min-width: 992px) {
.contact-info {
  font-size: 1.2rem;
  /* text-align: left; */
  justify-content: start;
}  
}