
.content-container {
    flex-wrap: wrap;
}

.left-header {
  color: var(--themeColor);
}

.left-paragraph {
  text-align: start;
}

.list-group {
  /* background-color: var(--themeColor); */
  list-style: none;
}

.link {
  text-decoration: none;
  font-size: 1.75rem;
}

@media (max-width: 912px) {
  .left-paragraph {
    text-align: center;
  }
}

@media (min-width: 913px) {
  .left-paragraph {
    font-size: 1.25rem;
  }

  .left-header {
    font-size: 3rem;
  }
}