.about-js {
  position: relative;
}

.paragraph {
  font-size: 1.15rem;
  padding-left: 10px;
}

.about-img {
  width: 100%;
}

.adp-disclosure {
  font-size: 1.5rem;
}

.paragraphs-container {
  margin-top: 20px;
}

@media (max-width: 991px) {
  .paragraph {
    font-size: 1rem;
  }
}