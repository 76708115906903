.submit-button {
    background: var(--admissionFormColor) !important;
    border-color: transparent !important;
}

.submit-button:hover {
    transition: all 0.3s ease-in-out;
    background: rgba(145, 85, 167, 0.761) !important;
}

.submit-button p {
    font-size: 1.25rem;
}

.submit-button:focus {
    outline: none
}