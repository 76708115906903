.admissions-container {
  min-height: 100vh;
}

.form-title {
  background: var(--admissionFormColor)
}

@media (max-width: 992px) {
    .admissions-container {
      flex-wrap: wrap-reverse;
    }

    .info-text {
      text-align: center;
      width: 100%;
    }
}