.landing-container {
    max-width: 100vw !important;
    overflow: hidden;
    height: 90vh;
    position: relative;
}

.hero-img {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../imgs/nurse_hero_img.jpeg");
    height: 90%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    animation: zoom-out 1.5s ease;
  }

  .text-container {
    width: 50%;
  }

  .ec-span {
    color: var(--themeColor);
    font-size: 3.25rem;
  }

  .carot-btn {
    position: absolute;
    bottom: -1.5rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    
  }
  
  .carot-btn button {
    border: 1px solid transparent;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background-color: white;
    z-index: 4 !important;
    box-shadow: 0px 5px 5px var(--shadowColor);
  }
  
  .fa-solid {
    color: black !important;
  }
  
  .hero-text, .hero-text-2 {
    animation: drop 1.5s ease;
  }
  
  .subtext {
    animation: rise 1.5s ease;
  }
  
  .cta-button {
    background-color: var(--themeColor);
    border: 1px solid var(--themeColor);
    border-radius: 100px;
    max-width: 200px;
    min-width: 200px;
    font-weight: 200 !important;
    /* color: whitesmoke; */
    font-size: 1.25rem;
    animation: rise 1.75s ease;
    color: black;
  }

  .cta-button:hover {
    background-color: transparent;
    color: whitesmoke;
    transition: all 0.5s ease;
  }
  
  .cta-button:hover .cta-text {
    color: whitesmoke;
  }
  
  .hero-text {
    color: white;
    font-size: 3rem;
  }
  
  .hero-text-2 {
    color: white;
    font-size: 2rem;
  }
  
  .subtext {
    font-size: 1.5rem;
  }

  @media (max-width: 912px) {
    .hero-img {
      background-attachment: scroll;
    }
  }

  @media (max-width: 992px) {
    .text-container {
      width: 100% !important;
    }

    .ec-span {
      color: var(--themeColor);
      font-size: 2rem;
    }

    .hero-text {
      font-size: 1.75rem;
    }

    .subtext {
      font-size: 1rem;
    }
  }