.footer-container {
  color: rgb(88, 88, 88);
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid rgb(215, 215, 215);
  margin-bottom: 20px;
  bottom: 0;
  width: 100%;
  /* position: absolute; */
}

.contact-link {
  color: rgb(88, 88, 88);
  text-decoration: none;
  text-align: center;
}

.contact-link:hover {
  color: black;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}

.fa-instagram {
  font-size: 1.5rem;
}

.fa-phone::before, .fa-envelope::before, .fa-map-location::before {
  color:rgb(88, 88, 88);
}