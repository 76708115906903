.our-services {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.list-item {
  padding-left: 20px;
}

.title {
  font-size: 2rem;
  margin-bottom: 24px;
}

.service {
  font-size: 1.75rem;
}

.services-group {
  columns: 2 !important;
  list-style: none;
  font-size: 24px;
  text-align: left;
}

.fa-check::before {
  color: var(--themeColor);
}

@media (max-width: 992px) {
  .services-group {
    columns: 1 !important;
  }

  .title {
    font-size: 1.5rem;
  }

  .service {
    font-size: 1.25rem
  }
}