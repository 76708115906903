.careers-container {
  min-height: 100vh;
}

.apply-link {
  text-decoration: none;
}

.text-cont {
  font-size: 1.25rem;
  
}

.hiring-title {
  color: var(--admissionFormColor);
  /* font-family: Great Vibes !important; */
}